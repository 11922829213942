<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header fw-bold" style="display: flex;justify-content: space-between;">
                        <div>
                            Order List
                        </div>
                        <div style="display: flex;">
                            <div class="ml-4"> 
                                Pending
                                <span class="badge badge-pill badge-primary mr-4" >{{dashboard.pending}}</span>
                            </div>
                            <div class="ml-2"> 
                                Confirmed
                                <span class="badge badge-pill badge-info mr-4" >{{dashboard.confirm}}</span>
                            </div>
                            <div class="ml-2"> 
                                Reject
                                <span class="badge badge-pill badge-danger mr-4" >{{dashboard.reject}}</span>
                            </div>
                            <!-- <div class="ml-2"> 
                                Shipped
                                <span class="badge badge-pill badge-info mr-4" >{{dashboard.shipping}}</span>
                            </div>
                            <div class="ml-2"> 
                                Delivered
                                <span class="badge badge-pill badge-info mr-4" >{{dashboard.delivered}}</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="From Date" v-model="filter.fromDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="To Date" v-model="filter.toDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" placeholder="Email" v-model="filter.email" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" placeholder="Order No" v-model="filter.orderNo" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1" >
                                            <select id="inputShop" class="form-control" v-model="filter.status" @change="validationRule()">
                                                <option value="">All Status</option>
                                                <option value="0">Pending</option>
                                                <option value="1">Confirm</option>
                                                <option value="2">Reject</option>
                                            </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">                            
                                    <div class="col-md-12 mt-1">
                                        <button class="btn btn-primary  mr-1 fbutton" @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info  mr-1 fbutton" @click="reset()"><i class="fas fa-sync"></i> Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize tbl_th_a">
                                                        {{value}}
                                                    </a>
                                                </th>
                                                <th class="th-width-100"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.orderNo}}</td>
                                                <td>{{data.name}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phone}}</td>
                                                <td>${{data.price}}</td>
                                                <td>{{data.quantity}}</td>
                                                <td>{{data.orderDate}}</td>
                                                <td >
                                                    <span class="badge badge-pill badge-primary" v-if="data.status == 0">Pending</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.status == 1">Confirm</span>
                                                    <span class="badge badge-pill badge-danger" v-if="data.status == 2">Reject</span>
                                                </td>
                                                <td style="padding: 0px;">
                                                    <button class="btn btn-option text-primary"
                                                        @click="showDetail(data)"><i class="fas fa-eye"></i></button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="9" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
        <b-modal id="bv-modal-mass" ref="my-modal" title="Mass Upload" hide-footer>
            <div class="bg-danger mb-3" v-for="err in errorList">
                <div class="card-header">Row No. {{err.row}}</div>
                <div class="card-body">
                    <p class="card-text" v-if="err.first_name">- {{err.first_name}}</p>
                    <p class="card-text" v-if="err.last_name">- {{err.last_name}}</p>
                    <p class="card-text" v-if="err.email">- {{err.email}}</p>
                    <p class="card-text" v-if="err.city">- {{err.city}}</p>
                    <p class="card-text" v-if="err.postal_code">- {{err.postal_code}}</p>
                    <p class="card-text" v-if="err.country">- {{err.country}}</p>
                    <p class="card-text" v-if="err.total_quantity">- {{err.total_quantity}}</p>
                    <p class="card-text" v-if="err.sku_id">- {{err.sku_id}}</p>
                    <p class="card-text" v-if="err.price">- {{err.price}}</p>
                    <p class="card-text" v-if="err.discountPrice">- {{err.discountPrice}}</p>
                    <p class="card-text" v-if="err.vat">- {{err.vat}}</p>
                    <p class="card-text" v-if="err.delivery_price">- {{err.delivery_price}}</p>
                    <p class="card-text" v-if="err.order_date">- {{err.order_date}}</p>
                    <p class="card-text" v-if="err.customize_status">- {{err.customize_status}}</p>
                </div>
            </div>	
            <form @submit.prevent="massUploadForm">
            <p class="my-4">
                <input class="form-control" type="file" @change="handleFileUpload" required>
            </p>
            <button class="mt-3  btn btn-success mass-btn"  type="submit" >Submit</button>
            </form>
        </b-modal>
        <b-modal id="bv-modal-pre" ref="my-modal" title="Pre Populate" hide-footer>
            <p class="my-4">Are you sure to generate ? </p>
            <div class="d-block text-center">
                <p class="text-danger" style="text-align: left;">{{this.alert}}</p>
                <input type="text" class="form-control" v-model="preSkuId" placeholder="SKU ID">
                <input type="number" class="form-control mt-2" v-model="cardQty" placeholder="Card Quantity">
            </div>
            <b-button class="mt-3 btn-success" block @click="generatePreCard()">Submit</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'orderList',
        metaInfo: {
            title: "Order List",
            titleTemplate: "%s ← RJ Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                dataList: [],
                errorList: null,
                massFile: null,
                cardQty: null,
                preSkuId: null,
                dashboard: [],
                columns: {
                    order_no: "Order No",
                    name: "name",
                    email: "email",
                    phone: "phone",
                    price: "price",
                    quantity: "quantity",
                    created_at: "Date",
                    status: "Status"
                },
                filter: {
                    page: 1,
                    orderBy: "desc",
                    sortKey: "created_at",
                    limit: 10,
                    fromDate: "",
                    toDate: "",
                    status: "",
                    userId: "",
                    orderNo: "",
                    email: "",
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                orderListAction: 'orderListAction',
                massUploadAction: 'massUploadAction',
                generatePreCardAction: 'generatePreCardAction',
                dashboardAction: 'dashboardAction'
            }),
            handleFileUpload(event) {
                this.massFile = event.target.files[0];
            },
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.orderListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'order-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, limit: this.filter.limit, fromDate: this.filter.fromDate, toDate: this.filter.toDate, 
                            status: this.filter.status, userId: this.filter.userId, email: this.filter.email, orderNo: this.filter.orderNo} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'order-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', limit: this.filter.limit, fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: ''} }).catch(()=>{})
                this.$router.go()
            },
            deleteData(data){
                this.id = data.id
            },
            massUpload(data){
                this.errorList = null
                this.massFile = null
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.orderDeleteAction({
					...option
				}).then(res => {
					this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            showDetail(data) {
                this.$router.replace({ path: 'sale-order-detail', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, id: data.id, shopId: this.filter.shopId, fromDate: this.filter.fromDate, toDate: this.filter.toDate, 
                    status: this.filter.status, userId: this.filter.userId, email: this.filter.email, orderNo: this.filter.orderNo} }).catch(()=>{})
            },
            async massUploadForm() {
				this.isLoading = true
   
                const formData = new FormData();
                formData.append('massFile', this.massFile); // Ensure correct key and file

               console.log('FormData:', formData); // Log FormData object
				await this.massUploadAction(
					formData
				).then(res => {
                    if(res.data.success == false){
                        this.errorList = res.data.data
                    }else{
                        this.fetchData()
                        this.$refs['my-modal'].hide()
                    }

                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            async generatePreCard() {
                this.isLoading = true
                let option = {
                    preSkuId: this.preSkuId,
					cardQty: this.cardQty
				}
  
                await this.generatePreCardAction({
                    ...option
                }).then(res => {
                    console.log(res.status)
                    if(res.status == "fail"){
                        this.alert = res.message
                    }else{
                        this.fetchData()
                        this.$refs['my-modal'].hide()
                    }
                    this.isLoading = false;
                }).catch(err => this.isLoading = true)
            },
            async dashboardDetail() {
                this.isLoading = true;
                await this.dashboardAction().then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            }

        },
        filters: {
            subStr: function(string) {
                let stLength = string.length
                if(stLength > 30){
                    return string.substring(0,30) + '...';
                }else{
                    return string
                }
            }
        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
            this.filter.status = this.$route.query.status
            this.filter.userId = this.$route.query.userId
            this.filter.email = this.$route.query.email
            this.filter.orderNo = this.$route.query.orderNo
            this.fetchData()
            this.dashboardDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .mass-btn {
        width: 100%;
    }
    .fbutton {
        width: 20%;
    }
</style>